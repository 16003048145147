import React from 'react'
import { Wheel } from 'react-custom-roulette'

const backgroundColors = ['#ff6f61', '#92a8d1', '#034f84', '#f7cac9']
const textColors = ['#fff']
const borderColor = '#eee'

const RouletteWheel = ({
	prizeResult,
	data,
	onStop,
	mustSpin,
	handleSpinClick,
}) => {
	return (
		<div className='flex flex-col items-center gap-5 relative'>
			<div className='rotate-[-45deg] shadow-effect'>
				<Wheel
					mustStartSpinning={mustSpin}
					prizeNumber={prizeResult}
					data={data}
					backgroundColors={backgroundColors}
					textColors={textColors}
					onStopSpinning={() => onStop()}
					outerBorderWidth={1}
					radiusLineWidth={1}
					outerBorderColor={borderColor}
					radiusLineColor={borderColor}
				/>
			</div>

			<button
				className='w-max border-2 border-black px-4 py-2 absolute bottom-[-80px] rounded-lg'
				onClick={() => handleSpinClick()}
				disabled={mustSpin}
			>
				Крутить
			</button>
		</div>
	)
}

export default RouletteWheel
