import React, { useEffect, useState } from "react";
import RouletteWheel from "./components/rouletteWheel";
import PrizeModal from "./components/prizeModal";
import { checkTicket, getPrizes, spinWheel } from "./apiService";

function App() {
  const [data, setData] = useState([
    { option: "Приз 1" },
    { option: "Приз 2" },
    { option: "Приз 3" },
    { option: "Приз 4" },
    { option: "Приз 5" },
    { option: "Приз 6" },
    { option: "Приз 7" },
    { option: "Приз 8" },
    { option: "Приз 9" },
    { option: "Приз 10" },
  ]);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [ticket, setTicket] = useState("");
  const [ticketValid, setTicketValid] = useState(false);
  const [error, setError] = useState("");

  const handleFinished = (winner) => {
    alert(`The winner is: ${winner}`);
  };

  const handleOpenModal = () => setIsOpenModal(true);

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setTicketValid(false);
  };

  const handleStop = () => {
    setMustSpin(false);
    handleOpenModal();
  };

  const handleCheckTicket = async () => {
    // ---------------- Раскоментировать код снизу для запроса что бы проверить билет на валидность и удалить свыше

    try {
      await fetchPrizes();
      const response = await checkTicket(ticket);
      if (response.message === "Ticket validated successfully") {
        setTicketValid(true);
        setError("");
      } else {
        setError("Билет недействителен. Пожалуйста, попробуйте снова.");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSpinClick = async () => {
    // ---------------- Раскоментировать код снизу для запроса что бы получить результат прокрутки и удалить свыше

    try {
      const spinResult = await spinWheel(ticket);
      const prizeTitle = spinResult.title;

      const prizeIndex = data.findIndex((prize) => prize.option === prizeTitle);

      if (prizeIndex !== -1) {
        setPrizeNumber(prizeIndex);
        setMustSpin(true);
      } else {
        setError("Приз не найден в списке");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  // ---------------- Раскоментировать код снизу для того что бы достать все призы

  // useEffect(() => {
  const fetchPrizes = async () => {
    try {
      const prizes = await getPrizes();
      console.log(prizes);
      setData(
        prizes.map((item) => ({
          option: item.title,
        }))
      );
    } catch (error) {
      setError(error.message);
    }
  };
  // fetchPrizes()
  // }, [])

  return (
    <div className="App">
      {!ticketValid ? (
        <div className="flex flex-col items-center justify-center h-screen">
          <input
            type="text"
            value={ticket}
            onChange={(e) => setTicket(e.target.value)}
            className="mb-2 p-2 border border-gray-300 rounded-lg"
            placeholder="Введите ваш билет"
          />

          {error && <p className="text-red-500">{error}</p>}

          <button
            onClick={handleCheckTicket}
            className="border-2 border-black px-4 py-2 rounded-lg"
          >
            Проверить билет
          </button>
        </div>
      ) : (
        <>
          <RouletteWheel
            prizeResult={prizeNumber}
            data={data}
            onStop={handleStop}
            mustSpin={mustSpin}
            handleSpinClick={handleSpinClick}
          />

          {isOpenModal && (
            <PrizeModal
              prize={data[prizeNumber].option}
              handleClose={handleCloseModal}
            />
          )}
        </>
      )}
    </div>
  );
}

export default App;
