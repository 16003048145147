const BASE_URL = 'https://fortune-api.dadkey.ru/api'

export const checkTicket = async ticket => {
	try {
		const response = await fetch(`${BASE_URL}/ticket/checkTicket/${ticket}`, {
			method: 'GET',
		})
		const res = await response.json()
		console.log(res)
		if (!response.ok) {
			throw new Error('Ошибка при проверке билета')
		}
		return res
	} catch (error) {
		console.error('Ошибка: ', error)
		throw error
	}
}

export const spinWheel = async ticket => {
	try {
		const response = await fetch(`${BASE_URL}/prize/spinWheel/${ticket}`, {
			method: 'GET',
		})
		if (!response.ok) {
			throw new Error('Ошибка при прокрутке колеса')
		}
		return await response.json()
	} catch (error) {
		console.error('Ошибка: ', error)
		throw error
	}
}

export const getPrizes = async () => {
	try {
		const response = await fetch(`${BASE_URL}/prize/getPrizes`, {
			method: 'GET',
		})
		if (!response.ok) {
			throw new Error('Ошибка при прокрутке колеса')
		}
		return await response.json()
	} catch (error) {
		console.error('Ошибка: ', error)
		throw error
	}
}
