import React from 'react'

const PrizeModal = ({ prize, handleClose }) => {
	const handleModalContentClick = e => {
		e.stopPropagation()
	}

	return (
		<div
			className='modal fixed z-10 w-full h-full top-0 left-0 flex items-center justify-center'
			onClick={handleClose}
		>
			<div
				className='modal-overlay absolute w-full h-full bg-gray-900 opacity-50'
				onClick={handleClose}
			></div>

			<div
				className='modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto'
				onClick={handleModalContentClick}
			>
				<div className=' flex flex-col items-center modal-content py-4 text-left px-6'>
					<div className='flex items-center pb-3'>
						<p className='text-xl font-bold text-center'>Вы выиграли:</p>
					</div>

					<p className='text-[40px] font-bold'>{prize}</p>
				</div>
			</div>
		</div>
	)
}

export default PrizeModal
